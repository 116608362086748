@import 'styles/abstract/mixins';

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 4rem;
  // place holder while bg video is loading
  background-image: url('../../../assets/images/staking-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @include respond(md) {
    padding: 2rem;
  }

  &::after {
    content: ' ';
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.1);
    z-index: 0;
  }
}

.select {
    background-color: #000000;
    opacity: 0.62;
    padding: 1rem;
    height: 4.3rem;
    width: 40rem;
    color: #fff;
    border-radius: 2rem;
    border:1.7px solid transparent;
    background: linear-gradient(#000000, #000000, #2E247A) padding-box, linear-gradient(to right, #322F81, #3F99CC) border-box;
    background-color: #000000;

    @include respond(md) {
        height: 4rem;
        width: 20rem;
        padding-top: 10px;
    }
}

.content {
    display: flex;
    flex-direction: column;
    margin: 0 14rem;
    z-index: 1;

    @include respond(md) {
        margin: 0 3rem;
      }
}

.row1 {
    display: flex;
    justify-content: space-between;
    height: 30rem;

    .buttonContaier {
        @include center(column);
        width: 30%;
    }

    .buttonConnect {
        background: linear-gradient(to right, #322F81, #3F99CC , #2E247A);
        border-radius: 40px;
        box-sizing: border-box;
        color: #fff;
        display: block;
        font-size: 1.7rem;
        font-weight: 200;
        width: 20rem;
        height: 5rem;
        letter-spacing: 1px;
        margin: 0 auto;
        padding: 3px;
        text-decoration: none;
        text-transform: uppercase;
        
        cursor: pointer;

        span {
            @include center();
            background: #040406;
            opacity: 0.8;
            border-radius: 40px;
            height: 100%;
            width: 100%;
            transition: background .5s ease;
        }

        &:hover {
            color: #fff;
            font-weight: 600;
            span {
                background: transparent;
            }
        }

        @include respond(md) {
            font-size: 1rem;
            width: 11rem;
            height: 3rem;
            padding: 1.5px;
        }
    }
    
    .buttonConnect1 { 
        @include button('../../../assets/images/ConnectWallet.png', 21rem, 5rem);
        &:hover {
            background-image: url('../../../assets/images/ConnectWallet-mouseover.png');
        }
    }

    .buttonExit {
        @include button('../../../assets/images/exit-portal.png', 21rem, 5rem);
        &:hover {
            background-image: url('../../../assets/images/exit-portal-mouseover.png');
        }
    }
}

.row2 {
    display: flex;
    justify-content: space-between;

    @include respond(md) {
        flex-direction: column;
        align-items: center;
      }
}

.row3 {
    @include center(column);
    margin-top: 1rem;

    img {
        width: 36rem;
    }
}

.nft {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    position: relative;
    @include button('../../../assets/images/NFT-Frame.png', 30%, 30%);

    @include respond(md) {
        width: 300px;
      }

    img {
        position: absolute;
        width: 33rem;
        @include respond(md) {
            width: 26rem;
        }

    }

    .bg-video {
        display: flex;
        object-fit: cover;
        pointer-events: none;
        height: 100%;
        width: 100%;
      }
}

.stakeCounter {
    @include center(column);
    width: 30%;

    .line {
        border-bottom: 1px solid var(--color-darkgrey);
        width: 10rem;
        margin: 4rem 0;
    }

    .countdownText {
        color: var(--color-grey);
        font-weight: 100;
        font-size: 1.8rem;
    }

    .countdownTimer {
        margin-top: 3rem;
        color: var(--color-aquamarine);
        font-weight: 500;
        letter-spacing: 3px;
        font-size: 26px;
    }
}

.stakePercentage {
    @include center(column);
    width: 30%;
    position: relative;

    img {
        position: absolute;
        margin: auto;
    }

    .stakePercentageText {
        @include center(column);
        color: var(--color-white);
        margin: auto;

        h1 { 
            font-size: 5rem;
        }

        span { 
            color: var(--color-grey);
            font-weight: 100;
            font-size: 1.8rem;
        }
    }

    @include respond(md) {
        img{
            height: 200px;
        } 

        h1 {
            font-size: 3rem;
        }
      }
}

.stakeFunctions {
    @include center(column);
    position: relative;
    width: 30%;

    @include respond(md) {
        width: initial;
      }

    .stakeRow {
        display: flex;
    }

    .stakeGroup {
        @include center(column);
        margin: 1rem;

        img {
            height: 9rem;
            width: 9rem;
        }

        .stakeButton {
            @include button('../../../assets/images/Stake.png', 19rem, 4rem);
            &:hover {
                background-image: url('../../../assets/images/Stake_rollover.png');
            }
        }
        .unStakeButton {
            @include button('../../../assets/images/Unstake.png', 19rem, 4rem);
            &:hover {
                background-image: url('../../../assets/images/Unstake_rollover.png');
            }
        }
        .pendingClaimButton {
            @include button('../../../assets/images/Token_Claim.png', 19rem, 4rem);
            &:hover {
                background-image: url('../../../assets/images/Token Claim_rollover.png');
            }
        }
        .claimButton {
            @include button('../../../assets/images/Claim.png', 19rem, 4rem);
            &:hover {
                background-image: url('../../../assets/images/Claim_rollover.png');
            }
        }

        @include respond(md) {
            img {
                height: 6rem;
                width: 6rem;
            }
    
            .stakeButton {
                @include button('../../../assets/images/Stake.png', 14rem, 3rem);
            }
            .unStakeButton {
                @include button('../../../assets/images/Unstake.png', 14rem, 3rem);
            }
            .pendingClaimButton {
                @include button('../../../assets/images/Token_Claim.png', 14rem, 3rem);
            }
            .claimButton {
                @include button('../../../assets/images/Claim.png', 14rem, 3rem);
            }
          }
    }

    .locked {
        pointer-events: none;
        opacity: 0.3;
    }

    .unlocked {
        pointer-events:initial;
        opacity: 1;
    }

    .lockImage {
        position: absolute;
        margin: auto;

        img {
            height: 25rem;
        }
    }
}